<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            v-if="isAccountMemberSubModule"
            link
            @click="getMembersList()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Account Members
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isRoleSubModule"
            link
            @click="getRoles()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Roles
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-account-multiple
                </v-icon>
              </v-list-item-icon>
              Members
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            v-if="isAccountMemberSubModule"
            link
            @click="getMembersList()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Account Members
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isRoleSubModule"
            link
            @click="getRoles()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Roles
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->
    <h2 class="title-style">
      Members 👥
    </h2>
    <p class="mt-0 mb-0 sub-title-style">
      Explore Members and their Details here.
    </p>
    <v-tabs
      v-model="tabs"
      class="ml-0"
    >
      <v-tab>
        <v-icon>
          mdi-account
        </v-icon> Account Members
      </v-tab>
      <v-tab>
        <v-icon class="mr-1">
          mdi-account-group
        </v-icon> Roles
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <members-list />
      </v-tab-item>
      <v-tab-item>
        <roles-list />
      </v-tab-item>
    </v-tabs-items>
    <div>
      <no-modules-found v-if="showNoModulePage" />
    </div>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

  <script>
   import MembersList from './MembersList.vue';
   import RolesList from './RolesList.vue';
   import ModuleHelper from 'src/helpers/module-helper';
   import Constants from 'src/constants';
   import NoModulesFound from './NoModulesFound.vue';
   import spinner from 'src/views/dashboard/component/SpinnerCentre';

   export default {
      name: 'Members',
      components: {
        'members-list': MembersList,
        'roles-list': RolesList,
        'no-modules-found': NoModulesFound,
        'centre-spinner': spinner,
      },
      data: () => ({
        selectedItem: -1,
        showMembersList: false,
        showRolesList: false,
        helper: new ModuleHelper(),
        showNoModulePage: false,
        loading: false,
        tabs: null,
      }),

      computed: {
        isMobile () {
          if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
            return false;
          } else {
            return true;
          }
        },
      },
      async mounted () {
        this.loading = true;
        await this.isAccountMemberSubModule();
        await this.isRoleSubModule();
        await this.checkIfModule();
        this.loading = false;
      },
      methods: {
         checkIfModule () {
       if (this.isAccountMemberSubModule) {
         this.getMembersList();
       } else if (this.isRoleSubModule) {
         this.getRoles();
       } else {
         this.showNoModulePage = true;
       }
        },
      isAccountMemberSubModule () {
        return (this.helper.isSubModuleExist(Constants.MODULE_MEMBERS, Constants.SUB_MODULE_ACCOUNT_MEMBERS));
      },
      isRoleSubModule () {
        return (this.helper.isSubModuleExist(Constants.MODULE_MEMBERS, Constants.SUB_MODULE_ROLES));
      },
        getMembersList () {
          this.showMembersList = true;
          this.showRolesList = false;
          this.showNoModulePage = false;
        },
        getRoles () {
          this.showMembersList = false;
          this.showRolesList = true;
          this.showNoModulePage = false;
        },
      },
    };

  </script>
  <style scoped>
  .nav-title-style {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: #37474F;
    font-size: 25px;
  }
  .alert-style {
    color: #C62828;
    font-size: 25px;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
  }
  .icon-style {
  color: #37474f;
  font-size: 45px;
}
.title-style {
  font-size: 36px;
  font-weight: 600;
}
.sub-title-style{
  color: #aaa;
}
  </style>
